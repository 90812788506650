import React, { useState, useEffect } from "react";

import "../components/navbar";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Link, useNavigate } from "react-router-dom";
import { FaInstagramSquare, FaFacebookSquare } from "react-icons/fa";

export default function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navigate = useNavigate();
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

  const handleClick = () => {
    navigate("/bookingForm");
  };

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+447935480327"
        accountName="Roll Safe Driving School"
      />
      <div className="header">
        <img className="logo" src="/logo.jpg" alt="Logo" />{" "}
        <div className="social-media-top">
          <a
            href="https://www.instagram.com/rollsafedrivingschool"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagramSquare size={40} color={"red"} />
          </a>
          <a
            href="https://www.facebook.com/Roll-Safe-Driving-School-100691982065526"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare size={40} color={"blue"} />
          </a>
        </div>
        <h2 className="phoneNumber">
          <Link
            className="myNum"
            to="https://api.whatsapp.com/send/?phone=00447935480327&text=d"
          >
            <BsFillTelephoneFill className="phoneIcon" /> 07935480327{" "}
          </Link>
          <button className="book-now" onClick={handleClick}>
            BOOK NOW
          </button>
        </h2>
      </div>

      <br></br>

      <nav className="nav">
        <ul className={active}>
          <li className="nav__item">
            <Link className="nav__link" to="/">
              Home
            </Link>{" "}
          </li>
          <li className="nav__item">
            <Link className="nav__link" to="/areasCovered">
              {" "}
              Areas Covered{" "}
            </Link>{" "}
          </li>
          <li className="nav__item">
            <Link className="nav__link" to="/pricing">
              Pricing
            </Link>{" "}
          </li>
          <li className="nav__item">
            <Link className="nav__link" to="/reviews">
              Reviews
            </Link>{" "}
          </li>
          <li className="nav__item">
            <Link className="nav__link" to="/bookingForm">
              Contact Us
            </Link>{" "}
          </li>
        </ul>
        <div onClick={navToggle} className={icon}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </div>
  );
}
