import React from "react";

export default function Pricing() {
  return (
    <div className="pricing-container">
      <h2>Manual Lessons</h2>
      <div className="pricing-category">
        <h3>Pay as you go</h3>
        <p>£34 per hour</p>
      </div>
      <div className="pricing-category">
        <h3>Discount packages (1 lesson a week)</h3>
        <ul>
          <li>£160 for 5 hours</li>
          <li>£330 for 10 hours</li>
          <li>£600 for 20 hours</li>
          <li>£840 for 30 hours</li>
        </ul>
      </div>
      <div className="pricing-category">
        <h3>Intensive courses (up to 4 hours a week)</h3>
        <ul>
          <li>£190 for 5 hours</li>
          <li>£350 for 10 hours</li>
          <li>£520 for 15 hours</li>
          <li>£680 for 20 hours</li>
          <li>£1000 for 30 hours</li>
        </ul>
      </div>
      <div className="pricing-category">
        <h3>Crash courses (between 4-10 hours a week)</h3>
        <ul>
          <li>£210 for 5 hours</li>
          <li>£380 for 10 hours</li>
          <li>£540 for 15 hours</li>
          <li>£720 for 20 hours</li>
        </ul>
      </div>

      <h2>Automatic Lessons</h2>
      <div className="pricing-category">
        <h3>Pay as you go</h3>
        <p>£37 per hour</p>
      </div>
      <div className="pricing-category">
        <h3>Discount packages (1 lesson a week)</h3>
        <ul>
          <li>£180 for 5 hours</li>
          <li>£350 for 10 hours</li>
          <li>£640 for 20 hours</li>
          <li>£870 for 30 hours</li>
        </ul>
      </div>
      <div className="pricing-category">
        <h3>Intensive courses (up to 4 hours a week)</h3>
        <ul>
          <li>£210 for 5 hours</li>
          <li>£370 for 10 hours</li>
          <li>£510 for 15 hours</li>
          <li>£700 for 20 hours</li>
          <li>£1000 for 30 hours</li>
        </ul>
      </div>
      <div className="pricing-category">
        <h3>Crash courses (between 4-10 hours a week)</h3>
        <ul>
          <li>£230 for 5 hours</li>
          <li>£405 for 10 hours</li>
          <li>£600 for 15 hours</li>
          <li>£780 for 20 hours</li>
        </ul>
      </div>
    </div>
  );
}
