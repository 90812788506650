import React from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Polygon } from "react-leaflet";
import { statesData } from "../data/map";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Map, GoogleApiWrapper } from "google-maps-react";

export default function AreasCovered() {
  const [inputValue, setInputValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const regex =
      /(M11|M12|M13|M14|M15|M16|M18|M19|M20|M21)\s?[0-9][A-Za-z]{2}$/gi;

    setIsValid(regex.test(value));
  };
  const handleClick = () => {
    if (isValid) {
      navigate("/bookingForm");
      console.log("omar");
    }
  };
  return (
    <div className="map-container">
      <MapContainer
        center={[53.443959, -2.244644]}
        zoom={12}
        style={{ height: "400px" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {statesData.features.map((state, index) => {
          const coordinates = state.geometry.coordinates[0].map((item) => [
            item[1],
            item[0],
          ]);

          return (
            <Polygon
              key={index}
              pathOptions={{
                fillColor: "#3A3B3C",
                fillOpacity: 0.2,
                weight: 2,
                opacity: 1,
                dashArray: 0,
                color: "#3A3B3C",
              }}
              positions={coordinates}
            />
          );
        })}
      </MapContainer>
      <div className="checkLocation">
        <label>
          Enter your Post Code:{" "}
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            style={{
              borderColor: inputValue ? (isValid ? "green" : "red") : "black",
              backgroundColor: inputValue
                ? isValid
                  ? "lightgreen"
                  : "lightpink"
                : "transparent",
              fontSize: 20,
            }}
          />
          <br></br>
          <br></br>
          {inputValue && (
            <>
              {isValid && <p> Great, We cover this area </p>}
              {!isValid && <p>Sorry we don't cover this area.</p>}
            </>
          )}
        </label>

        <br />
        <button className="checkLocationButton" onClick={handleClick}>
          Submit
        </button>
      </div>
      <div className="areas">
        <h1 className="heading">Areas Covered</h1>
        <div className="lists">
          <ul>
            <li> Ancoats</li>
            <li>Ardwick</li>
            <li>Beswick</li>
            <li>Burnage</li>
            <li>Clayton</li>
            <li>Didsbury</li>
            <li>East Didsbury</li>
            <li>FallowField</li>
            <li>Gorton</li>
            <li>Hulme</li>
            <li>Levenshulme</li>
            <li>Longsight</li>
            <li>Manchester City Centre</li>
            <li>Manchester</li>
            <li>Stockport</li>
            <li>West Didsbury</li>
            <li>West Gorton</li>
            <li>Withington</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
