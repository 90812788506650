import React from "react";
import { Link } from "react-router-dom";

export default function Reviews() {
  const imagePaths = [];

  // Loop to dynamically import images
  for (let i = 2; i <= 28; i++) {
    const image = require(`../data/imagesReview/review${i}.png`);
    imagePaths.push(image);
  }

  return (
    <div className="reviewsPage">
      <Link
        to={"https://g.page/r/CYwz1EmbgiAjEBM/review"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="reviews-container">
          <div className="reviews-header">
            <h2>Customer Reviews</h2>
            <p>Read what our customers have to say about us.</p>
          </div>
        </div>
        <div className="content">
          {imagePaths.map((image, index) => (
            <img
              className="review-image"
              key={index}
              src={image}
              alt={`Review ${index + 2}`}
            />
          ))}
        </div>
      </Link>
    </div>
  );
}
