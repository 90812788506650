import React from "react";
import { BsEnvelope } from "react-icons/bs";
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaWhatsappSquare,
} from "react-icons/fa";

import { MdLocationOn } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";

export default function Courses() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/bookingForm");
  };
  return (
    <div>
      <div className="homeImgContainer">
        <img
          className="learnerImg"
          src={require("../data/imagesReview/RSBanner.png")}
          alt=""
        />
      </div>
      <div className="about">
        <h1 className="heading">About us</h1>
        <div className="row">
          <div className="image">
            <img
              className="imgg"
              src={require("../data/imagesReview/examinar.jpeg")}
              alt=""
            />
          </div>

          <div className="content">
            <h3>Why choose Roll Safe Driving School</h3>
            <p>
              {" "}
              Roll Safe Driving School is your premier choice for top-notch
              driving lessons in the Manchester area. Our team of DVSA approved
              instructors is committed to providing personalized instruction
              tailored to your unique learning style and pace. We understand
              that obtaining your driver's license is a milestone moment, and at
              Roll Safe Driving School, we're dedicated to helping you achieve
              that goal swiftly and confidently. Join us today and embark on the
              journey to becoming a safe and skilled driver.
            </p>

            <button className="btn" onClick={handleClick}>
              Learn more
            </button>
          </div>
        </div>
        <br></br>
      </div>

      <div className="containerManchester">
        <h1 className="heading">Manchester Lessons </h1>

        <div className="box-container">
          <div className="box">
            <div className="innerColumBox">
              <h3 className="icontile">Automatic Lessons</h3>
              <p>
                Looking for automatic driving lessons? Look no further! We also
                provide comprehensive instruction in automatic vehicles to suit
                your preferences and needs.
              </p>
              <button className="btn" onClick={handleClick}>
                Learn more
              </button>
            </div>
          </div>

          <div className="box">
            <div className="innerColumBox">
              <h3 className="icontile">Intensive Lessons</h3>
              <p>
                Our goal is to accommodate the diverse needs of all our
                learners. Contact us today to explore how we can assist you in
                getting on the road sooner.
              </p>
              <button className="btn" onClick={handleClick}>
                Learn more
              </button>
            </div>
          </div>

          <div className="box">
            <div className="innerColumBox">
              <h3 className="icontile">Crash Courses</h3>

              <p>
                {" "}
                Our efficient approach ensures that you're fully prepared for
                your driving test without delay. So, why wait? Get started now
                and fast-track your way to success on the road..
              </p>
              <button className="btn" onClick={handleClick}>
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="areas">
        <h1 className="heading">Areas Covered</h1>
        <div className="lists">
          <ul>
            <li> Ancoats</li>
            <li>Ardwick</li>
            <li>Beswick</li>
            <li>Burnage</li>
            <li>Clayton</li>
            <li>Didsbury</li>
            <li>East Didsbury</li>
            <li>FallowField</li>
            <li>Gorton</li>
            <li>Hulme</li>
            <li>Levenshulme</li>
            <li>Longsight</li>
            <li>Manchester City Centre</li>
            <li>Manchester</li>
            <li>Stockport</li>
            <li>West Didsbury</li>
            <li>West Gorton</li>
            <li>Withington</li>
          </ul>
        </div>
      </div>

      <div className="experience">
        <div className="rollsafe">
          <h1 className="heading">Roll Safe experience</h1>

          <div className="row">
            <div className="image">
              <img
                className="imggg"
                src={require("../data/imagesReview/insta.jpg")}
                alt=""
              />
            </div>
            <div className="content">
              <p>
                Discover the difference with Roll Safe Driving School, where
                we're dedicated to providing top-notch Manchester driving
                lessons designed to suit your schedule and budget. Our team of
                experienced instructors is renowned for their patience,
                encouragement, and unwavering support, ensuring that you feel
                confident behind the wheel from your very first lesson.
                <br></br>
                <br></br>
                We understand the importance of affordability, which is why we
                offer cheap driving lessons without compromising on quality.
                Whether you're a complete beginner or looking to brush up on
                your skills, our practical driving lessons are tailored to your
                individual needs, guaranteeing a personalized learning
                experience that maximizes your progress.
                <br></br>
                <br></br>
                At Roll Safe Driving School, we take pride in our outstanding
                passing rate, a testament to our commitment to excellence and
                dedication to helping our students succeed. With countless
                5-star Google reviews from satisfied learners, you can trust us
                to provide the best driving lessons Manchester has to offer.
                <br></br>
                <br></br>
                Preparing for your driving test can be daunting, but with Roll
                Safe Driving School by your side, you can approach it with
                confidence. Our instructors are experts at preparing students
                for early driving tests, equipping you with the skills and
                knowledge needed to pass on your first attempt.
                <br></br>
                <br></br>
                Join the countless individuals who have trusted and recommended
                Roll Safe Driving School as one of Manchester's premier driving
                schools. Experience the difference for yourself and start your
                journey towards becoming a confident and skilled driver today.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bottomContainer">
        <img
          className="logoBottom"
          src={require("../data/imagesReview/blackLogo.png")}
          alt=""
        />
        <ul className="bottomInfo">
          <h2> Contact </h2>
          <li>
            {" "}
            <br></br>
            <NavLink to="https://api.whatsapp.com/send/?phone=00447935480327&text=d">
              {" "}
              <FaWhatsappSquare size={25} /> Call: 07935480327
            </NavLink>
          </li>

          <li>
            <br></br>
            <NavLink to="/contactUs">
              {" "}
              <BsEnvelope /> Email: info@rollsafedrivingschool.co.uk
            </NavLink>
          </li>

          <li>
            <br></br>
            <NavLink to="/areasCovered">
              {" "}
              <MdLocationOn />
              Roll Safe Driving School, Hartington Street, Manchester, M14 4RQ
            </NavLink>
          </li>
        </ul>
        <div className="social-media">
          <a
            href="https://www.instagram.com/rollsafedrivingschool"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagramSquare size={50} />
          </a>
          <a
            href="https://www.facebook.com/Roll-Safe-Driving-School-100691982065526"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare size={50} />
          </a>
        </div>
      </div>
    </div>
  );
}
