import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./BookingForm.css";

import Courses from "./components/Courses";
import Navbar from "./components/navbar";

import AreasCovered from "./components/AreasCovered";
import Pricing from "./components/Pricing";
import Reviews from "./components/Reviews";

import BookingForm from "./components/BookingForm";

function App() {
  return (
    <div>
      <div className="App">
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={<Courses />} />
        <Route path="/areasCovered" element={<AreasCovered />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/bookingForm" element={<BookingForm />} />
      </Routes>
    </div>
  );
}

export default App;
