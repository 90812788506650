import React, { useState } from "react";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BookingForm = () => {
  const [errors, setErrors] = useState({
    phoneNumber: "",
    postcode: "",
    emailAddress: "",
  });

  const [state, setState] = useState({
    firstName: "",
    surname: "",
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    postcode: "",
    gearboxType: "",
    selectedPrice: "",
    drivingExperience: "",
  });

  const {
    firstName,
    surname,
    dateOfBirth,
    email,
    phoneNumber,
    postcode,
    gearboxType,
    selectedPrice,
    drivingExperience,
  } = state;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let selectedPrice = state.selectedPrice;
    if (name === "gearboxType") {
      selectedPrice = "";
    }

    if (name === "selectedPrice") {
      selectedPrice = value;
    }

    setState((prevData) => ({
      ...prevData,
      [name]: value,
      selectedPrice: selectedPrice,
    }));
    if (name === "emailAddress") {
      // eslint-disable-next-line
      const emailRegex = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailAddress: emailRegex.test(value)
          ? ""
          : "Please enter a valid email address. ",
      }));
    } else if (name === "phoneNumber") {
      const phoneRegex = /^(?:0|\+?44)(?:\d\s?){9,10}$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: phoneRegex.test(value)
          ? ""
          : "Please enter a valid phone number.",
      }));
    } else if (name === "postcode") {
      const postcodeRegex =
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2}$)/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        postcode: postcodeRegex.test(value)
          ? ""
          : "Please enter a valid postcode.",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("http://localhost:8081/api/post", {
        firstName,
        surname,
        dateOfBirth,
        email,
        phoneNumber,
        postcode,
        gearboxType,
        selectedPrice,
        drivingExperience,
      })
      .then(() => {
        setState({
          firstName: "",
          surname: "",
          dateOfBirth: "",
          email: "",
          phoneNumber: "",
          postcode: "",
          gearboxType: "",
          selectedPrice: "",
          drivingExperience: "",
        });
      })
      .catch((err) => toast.error(err.response.data));
    toast.success(
      "Contact details Submitted. An instructor will contact you shortly",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      }
    );
  };

  return (
    <div className="form-container">
      <h2>Booking Form</h2>
      <form onSubmit={handleSubmit}>
        <label>
          First Name:
          <input
            type="text"
            name="firstName"
            value={state.firstName}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Surname:
          <input
            type="text"
            name="surname"
            value={state.surname}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Date of Birth:
          <input
            type="date"
            name="dateOfBirth"
            value={state.dateOfBirth}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Email Address:
          <input
            type="email"
            name="email"
            value={state.email}
            onChange={handleChange}
            required
          />
          {errors.emailAddress && (
            <p style={{ color: "red" }}>{errors.emailAddress}</p>
          )}
        </label>
        <label>
          Phone Number:
          <input
            type="tel"
            name="phoneNumber"
            value={state.phoneNumber}
            onChange={handleChange}
            required
          />
          {errors.phoneNumber && (
            <p style={{ color: "red" }}>{errors.phoneNumber}</p>
          )}
        </label>
        <label>
          Postcode:
          <input
            type="text"
            name="postcode"
            value={state.postcode}
            onChange={handleChange}
            required
          />
          {errors.postcode && <p style={{ color: "red" }}>{errors.postcode}</p>}
        </label>
        <label>
          Gearbox Type:
          <select
            name="gearboxType"
            value={state.gearboxType}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="manual">Manual</option>
            <option value="automatic">Automatic</option>
          </select>
        </label>
        {state.gearboxType === "manual" && (
          <label>
            Select Manual Lesson Package:
            <select
              name="selectedPrice"
              value={state.selectedPrice}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="34">Pay as you go £34 per hour</option>

              <optgroup label="Discount packages">
                <option value="160">£160 for 5 hours</option>
                <option value="330">£330 for 10 hours</option>
                <option value="600">£600 for 20hours</option>
                <option value="840">£840 for 30hours</option>
              </optgroup>

              <optgroup label="Intensive course">
                <option value="190">£190 for 5 hours</option>
                <option value="350">£350 for 10 hours</option>
                <option value="680">£680 for 20hours</option>
                <option value="950">£950 for 30hours</option>
              </optgroup>

              <optgroup label="Crash course">
                <option value="210">£210 for 5 hours</option>
                <option value="380">£380 for 10 hours</option>
                <option value="540">£540 for 15 hours</option>
                <option value="720">£720 for 20hours</option>
              </optgroup>
            </select>
          </label>
        )}
        {state.gearboxType === "automatic" && (
          <label>
            Select Automatic Lesson Package:
            <select
              name="selectedPrice"
              value={state.selectedPrice}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="37">Pay as you go £37 per hour</option>

              <optgroup label="Discount packages">
                <option value="180">£180 for 5 hours</option>
                <option value="340">£340 for 10 hours</option>
                <option value="620">£620 for 20hours</option>
                <option value="870">£870 for 30hours</option>
              </optgroup>

              <optgroup label="Intensive course">
                <option value="210">£210 for 5 hours</option>
                <option value="360">£360 for 10 hours</option>
                <option value="680">£680 for 20hours</option>
                <option value="1000">£950 for 30hours</option>
              </optgroup>

              <optgroup label="Crash course">
                <option value="230">£230 for 5 hours</option>
                <option value="410">£410 for 10 hours</option>
                <option value="780">£780 for 20hours</option>
                <option value="1100">£1100 for 30hours</option>
              </optgroup>
            </select>
          </label>
        )}
        <label>
          Driving Experience:
          <textarea
            name="drivingExperience"
            value={state.drivingExperience}
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Submit</button>
        <ToastContainer />
      </form>
    </div>
  );
};

export default BookingForm;
