export const statesData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: { name: "areasCovered" },
      geometry: {
        coordinates: [
          [
            [-2.2731216580618536, 53.450665189028996],
            [-2.281168876354485, 53.442499366955076],
            [-2.232511884812425, 53.41437268677541],
            [-2.1869205511210623, 53.4388380178782],
            [-2.194253424614658, 53.46943850187182],
            [-2.2564857853078024, 53.47689279532773],
            [-2.276715804377204, 53.46218867892074],
            [-2.2731216580618536, 53.450665189028996],
          ],
        ],
        type: "Polygon",
      },
      id: 0,
    },
  ],
};
